import { Component } from "react";
import useState from "react";
import SelectJob from "./SelectJob";
import SelectProject from "./SelectProject";
import SelectWorkType from "./SelectWorkType";
import SelectHour from "./SelectHour";
import SaveButton from './SaveButton';
import EnterDescription from "./EnterDescription";
import { WorkTime } from '../../../models/work-time';
import { Project } from '../../../models/project';
import { Jobs } from '../../../models/jobs';
import { WorkType } from '../../../models/work-type';
import { EmployeeID } from '../../../models/employee-id';
import { Employee } from '../../../models/employee';

import "bootstrap/dist/css/bootstrap.min.css";

// IMPORTANT you need to include the default styles
import "react-responsive-tabs/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "../../../css/App.css";
import * as React from "react";
import { TimesheetEntry } from "../../../models/time-sheet-entry";

export type EditTimeFormViewModel = {
    selectedjob: number;
    selectedproject: number;
    selectedworktype: number;
    enteredhours: number;
    entereddescription: string;
    projects: Project[];
    enabledbutton: boolean;
    timeEntry: TimesheetEntry | null;
    isInitialized: boolean;
    currentId: number;
};

export type EditTimeFormProps = {
    workTypes?: WorkType[];
    jobs?: Jobs[];
    id?: number;
    handleEditTime?: () => void;
};

const innerRef = React.createRef<HTMLDivElement>();

export class EditTimeForm extends Component<EditTimeFormProps, EditTimeFormViewModel> {
  constructor(props) {
      super(props);
      this.state = {
          selectedjob: -1,
          selectedproject: -1,
          selectedworktype: -1,
          enteredhours: -1,
          entereddescription: "",
          projects: [],
          enabledbutton: false,
          timeEntry: null,
          isInitialized: false,
          currentId: -1
      };
      this.handleWorkTypeChange = this.handleWorkTypeChange.bind(this);
      this.handleProjectChange = this.handleProjectChange.bind(this);
      this.handleJobChange = this.handleJobChange.bind(this);
      this.handleHourChange = this.handleHourChange.bind(this);
      this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    }

    

    getTimeEntry(entryId: number) {
        console.log("calling EditTimeForm/GetTimeEntry");
        console.log("Current = " + this.state.currentId);
        fetch(`/api/timesheets/GetTimeEntry?entryId=` + entryId)
            .then(response => response.json() as Promise<TimesheetEntry>)
            .then(data => {
                console.log ("returned from EditTimeForm/GetTimeEntry")
                this.setState({
                    timeEntry: data,
                    currentId: entryId
                });
                console.log("Current = " + this.state.currentId);
                console.log("Attempting to set job, project and worktype");
                this.handleJobChange({value: this.state.timeEntry?.jobAutoId });
                this.handleProjectChange({ value: this.state.timeEntry?.projID });
                this.handleWorkTypeChange({ value: this.state.timeEntry?.workTypeID });
                this.handleHourChange([this.state.timeEntry?.timeHours]);
                this.handleDescriptionChange({ target: { value: this.state.timeEntry?.workDesc } });
                this.setState({
                    isInitialized: true,
                });
            });
    }

    getProjects(jobId: number) {
        console.log("TimeForm/getProjects="+jobId);
        fetch(`/api/timesheets/GetProjects?jobId=` + jobId)
            .then(response => response.json() as Promise<Project[]>)
            .then(data => {
                this.setState({
                    projects: data
                });
            });
    }


    updateTimeEntry = () => {
        console.log("In EditTimeFrom/updateTimeEntry");
        var d = new Date();
        var date = d.getFullYear() + '-' + ("0" + (d.getMonth() + 1)).slice(-2) + '-' + ("0" + d.getDate()).slice(-2)


        const data = {
            NumTimeId: this.state.timeEntry?.timeId,
            NumHours: this.state.enteredhours,
            NumWorkTypeId: this.state.selectedworktype,
            DtDate: date,
            TxtDesc:  this.state.entereddescription,
            NumProjectId: this.state.selectedproject,
            NumEmployeeId: this.state.timeEntry?.employeeId,
        }
        console.log(data);

        fetch(`/api/timesheets/UpdateTime/`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success Updating Time:', data);
                console.log("in TimeForm about to call handleAddTime");
                console.log(this);
                this.props.handleEditTime?.();
                this.setState({
                    selectedjob: -1,
                    selectedproject: -1,
                    selectedworktype: -1,
                    enteredhours: -1,
                    entereddescription: "-",
                    projects: [],
                    enabledbutton: false
                }
                );
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }


    afterSetStateFinished = () => {
        let enabled = this.state.selectedjob > 0 && this.state.selectedproject > 0 && this.state.selectedworktype > 0 && this.state.enteredhours > -1 && this.state.entereddescription.length > 5;
        this.setState({
            enabledbutton: enabled,
        });

    }

    handleJobChange = (selectedOption) => {
        this.getProjects(selectedOption.value);
        this.setState({
            selectedjob: selectedOption.value,
        }, () => {
            this.afterSetStateFinished();
        });
    };

    handleProjectChange = (selectedOption) => {
        this.setState({
            selectedproject: selectedOption.value
        }, () => {
            this.afterSetStateFinished();
        });
    };

    handleWorkTypeChange = (selectedOption) => {
        this.setState({
            selectedworktype: selectedOption.value,
        });
    };

    handleHourChange = (values) => {
        console.log("in EditTimeForm / this.handleHourChange");
        this.setState({
            enteredhours: values[0],
        }, () => {
            this.afterSetStateFinished();
        });
    };

    handleDescriptionChange = (selectedOption) => {
        this.setState({
            entereddescription: selectedOption.target.value,
        }, () => {
            this.afterSetStateFinished();
        });
    };

    handleSaveButton = (selectedOption) => {
        console.log("Button Clicked 2 in Time Form");
        console.log(this.state.selectedjob);
        console.log(this.state.selectedproject);
        console.log(this.state.enteredhours);
        console.log(this.state.entereddescription);
        this.updateTimeEntry();
    };

    componentDidUpdate(prevProps) {
        console.log('EditTimeForm/componentDidUpdate');
        console.log('this.props.id = ' + this.props.id);
        console.log('this.state.currentId = ' + this.state.currentId);
        if (this.props.id != this.state.currentId) {
            console.log('ID does not match current:' + this.props.id + ' - ' + this.state.currentId);
            this.getTimeEntry(this.props.id ?? -1);
        } else if (!this.state.isInitialized && this.props.id > 0) {
            console.log('Not initialized;  props.id > 0 : calling getTimeEntry');
            this.getTimeEntry(this.props.id);
        } else {
            console.log('Not calling getTimeEntry');
            console.log("isInitialized = "+this.state.isInitialized);
            console.log("props.id = " + this.props.id);
            console.log("this.state.currentId = " + this.state.currentId);
        }
    }

    getWrapperRef = () => {
        return innerRef;
    }

    render() {
        return this.state.isInitialized ? (
            <div className="container edit-time-form" ref={innerRef}>
        <div className="row select-drop-down">
                <div className="col-md-6">
                    <SelectJob jobs={this.props.jobs} handleChange={this.handleJobChange} selectedJobValue={this.state.timeEntry?.jobAutoId} selectedJobLabel={this.state.timeEntry?.jobNumber + " - " + this.state.timeEntry?.jobDesc} />
          </div>
        </div>
        <div className="row select-drop-down">
                    <div className="col-md-6">
                        <SelectProject projects={this.state.projects} selectedProjectValue={this.state.timeEntry?.projID} selectedProjectLabel={this.state.timeEntry?.projDesc} handleChange={this.handleProjectChange} />
          </div>
        </div>
        <div className="row select-drop-down">
                    <div className="col-md-6">
                        <SelectWorkType worktypes={this.props.workTypes} selectedWorkTypeValue={this.state.timeEntry?.workTypeID} selectedWorkTypeLabel={this.state.timeEntry?.workType} handleChange={this.handleWorkTypeChange} />
          </div>
        </div>
        <div className="row select-hour">
                    <div className="col-md-6">
                        <SelectHour selectedHours={this.state.timeEntry?.timeHours ?? 0} handleChange={this.handleHourChange} />
          </div>
        </div>
        <div className="row enter-description">
                    <div className="col-md-6">
                        <EnterDescription defaultValue={this.state.timeEntry?.workDesc ?? ''} handleChange={this.handleDescriptionChange} />
          </div>
        </div>
        <div className="row enter-description">
                <div className="col-md-6">
                    <SaveButton saveHandler={this.handleSaveButton}  enabled={this.state.enabledbutton} />
          </div>
        </div>
      </div>
        ) : (
                <div>Loading... </div>
            )
  }
}

export const EditTimeFormRef = React.forwardRef((props, ref: React.ForwardedRef<EditTimeForm>) => <EditTimeForm
    ref={ref} {...props}
/>);