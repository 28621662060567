import React, { Component } from "react";
import Select from "react-select";
//import { Tabs, Tab } from "react-bootstrap";
import { Jobs } from "../../../models/jobs";



export type SelectJobProps = {
    jobs: Jobs[] | undefined;
    handleChange: (selectedValue) => void;
    selectedJobValue?: number | null;
    selectedJobLabel?: string;
}

export type SelectJobViewModel = {
};

class SelectJob extends Component<SelectJobProps, SelectJobViewModel> {
    constructor(props) {
        super(props);
    }


    render() {
        let jobOptions = this.props.jobs?.map(p => { return { label: p.txtJobId + " - " + p.txtJobDesc, value: p.numJobId }; });
        let defaultSelect = [{ label: this.props.selectedJobLabel, value: this.props.selectedJobValue  }]
        return (
            <Select id="jobId" options={jobOptions} defaultValue={defaultSelect} onChange={e => this.props.handleChange(e)} />
    );
  }
}

export default SelectJob;
