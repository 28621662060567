import React, { useState, useRef, Ref } from 'react';
import { AddTimeForm, AddTimeFormRef } from './edit/AddTimeForm';
import { EditTimeForm, EditTimeFormRef } from './edit/EditTimeForm';
import { WorkTime } from '../../models/work-time';
import { Project } from '../../models/project';
import { Jobs } from '../../models/jobs';
import { WorkType } from '../../models/work-type';
import { TimesheetEntry } from '../../models/time-sheet-entry';
import { EmployeeID } from '../../models/employee-id';
import { Helpers } from '../../etc/helpers';
import { AddTimeViewModel } from './AddTime';
import { Button, Collapse, Card, CardBody } from 'reactstrap';
import ShowHoursIcons from './edit/ShowHoursIcons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faClose } from '@fortawesome/free-solid-svg-icons'


export type DailyTimesheetViewModel = {
  timeEntries: AddTimeViewModel[];
  isAddOpen: boolean;
  isRecentOpen: boolean;
  isEditOpen: boolean;
  selectedEdit: number;
  isInitialized: boolean;
  isAddInitialized: boolean;
  isEditInitialized: boolean;
  workTypes: WorkType[];
  projects: Project[];
  jobs: Jobs[];
  tse: TimesheetEntry[];
  rtse: TimesheetEntry[];
  message: string;
}


class DailyTimesheet extends React.Component<{ employee_id: number, employee_email: string }, DailyTimesheetViewModel> {
  addRef: React.RefObject<AddTimeForm>;
  editRef: React.RefObject<EditTimeForm>;
  keyCount: number;

  constructor(props) {
    super(props);
    this.addRef = React.createRef();
    this.editRef = React.createRef();
    this.getKey = this.getKey.bind(this);
    this.state = {
      timeEntries: [],
      isAddOpen: false,
      isRecentOpen: false,
      isEditOpen: false,
      selectedEdit: -1,
      isInitialized: false,
      isAddInitialized: false,
      isEditInitialized: false,
      workTypes: [],
      projects: [],
      jobs: [],
      tse: [],
      rtse: [],
      message: "",
    };
    this.keyCount = 0;
    this.handleAddTime = this.handleAddTime.bind(this);
    //    const empIdCookie = document.cookie
    //      .split('; ')
    //      .find(row => row.startsWith('ts2020-employeeid='))
    //      ?.split('=')?.[1] ?? '';
    //
    //    const empId = parseInt(empIdCookie);
    //      if (this.props.employee_id > 0) {
    //          this.getJobs(this.props.employee_id);
    //          this.getWorkTypes(this.props.employee_id);
    ///      }
    if (!this.state.isInitialized && this.props.employee_id > 0) {
      this.getTimeEntries(this.props.employee_id);
      this.getRecentTimeEntries(this.props.employee_id);
    }
  }


  getKey() {
    return this.keyCount++;
  }

  showRecentTime = () => {

  }
  handleAddTime = () => {
    console.log("in DailyTimesheet/handleAddTime");
    this.getTimeEntries(this.props.employee_id);
    this.setState({ isAddOpen: !this.state.isAddOpen })
  };

  handleEditTime = () => {
    console.log("in DailyTimesheet/handleEditTime");
    this.getTimeEntries(this.props.employee_id);
    this.setState({ isEditOpen: !this.state.isEditOpen })
    this.setState({ selectedEdit: -1 })
    this.setState({ isEditInitialized: false })
  };

  getTimeEntries(employeeId: number) {
    console.log("DailyTimesheet/getTimeEntries");
      fetch(`/api/timesheets/GetTimeEntries?employeeId=` + employeeId + `&dt=` + this.getCurrentDate())
      .then(response => response.json() as Promise<TimesheetEntry[]>)
      .then(data => {
        this.setState({
          tse: data,
        });
      });
  }

  getRecentTimeEntries(employeeId: number) {
    console.log("DailyTimesheet/getTimeEntries");
      fetch(`/api/timesheets/GetRecentTimeEntries?employeeId=` + employeeId)
      .then(response => response.json() as Promise<TimesheetEntry[]>)
      .then(data => {
        this.setState({
          rtse: data,
        });
      });
  }

  getCurrentDate() {
    var tempDate = new Date();
    var date = tempDate.getFullYear() + '-' + (tempDate.getMonth() + 1) + '-' + tempDate.getDate();
    const currDate = date;
    return currDate;
  }

  getJobs(employeeId: number) {
    console.log("DailyTimesheet/getJobs");
      fetch(`/api/timesheets/GetOpenJobs?employeeId=` + employeeId)
      .then(response => response.json() as Promise<Jobs[]>)
      .then(data => {
        this.setState({
          jobs: data
        });
      });
  }

  getWorkTypes(employeeId: number) {
    console.log("DailyTimesheet/getWorkTypes");
      fetch(`/api/timesheets/GetWorkTypes?employeeId=` + employeeId)
      .then(response => response.json() as Promise<WorkType[]>)
      .then(data => {
        this.setState({
          workTypes: data
        });
      });
  }

  deleteTimeEntry(entryId: number) {
    console.log("DailyTimesheet/deleteTimeEntry");
      fetch(`/api/timesheets/DeleteTimeEntry?entryId=` + entryId,
      {
        method: 'POST', // or 'PUT'
      })
      .then(response => response.json() as Promise<Boolean>)
      .then(data => {
        this.setState({
          isInitialized: false,
          message: "Time Entry deleted."
        });
      });
  }
  duplicateTimeEntry(entryId: number | null) {
    if (entryId === null) {
      return;
    }
        console.log("DailyTimesheet/duplicateTimeEntry");
      fetch(`/api/timesheets/DuplicateTimeEntry?entryId=` + entryId,
      {
        method: 'POST', // or 'PUT'
      })
      .then(response => response.json() as Promise<Boolean>)
      .then(data => {
        this.setState({
          isInitialized: false,
          message: "Time Entry duplicated."
        });
      });
  }

  componentDidUpdate(prevProps) {
    console.log('DailyTimesheet/componentDidUpdate');
    console.log('this.addRef = ');
    console.log(this.addRef.current?.getWrapperRef().current);


    if (!this.state.isInitialized && this.props.employee_id > 0) {
      this.getJobs(this.props.employee_id);
      this.getWorkTypes(this.props.employee_id);
      this.getTimeEntries(this.props.employee_id);
      this.getRecentTimeEntries(this.props.employee_id);
      this.setState({
        isInitialized: true
      })
    }

    if (!this.state.isAddInitialized && this.state.isAddOpen && this.props.employee_id > 0) {
      this.getJobs(this.props.employee_id);
      this.getWorkTypes(this.props.employee_id);
      this.setState({
        isAddInitialized: true
      });
      console.log("trying to scroll to add form");
      setTimeout(() => this.addRef.current?.getWrapperRef().current?.scrollIntoView(true), 500);
    }

    if (!this.state.isEditInitialized && this.state.isEditOpen && this.props.employee_id > 0) {
      this.getJobs(this.props.employee_id);
      this.getWorkTypes(this.props.employee_id);
      this.setState({
        isEditInitialized: true
      })
      setTimeout(() => this.editRef.current?.getWrapperRef().current?.scrollIntoView(true), 500);
    }

  }




  render() {
    console.log("DailyTimesheets/Render");
    let timeEntered = false;
    if (this.state.tse.length > 0) {
      timeEntered = true;
    }
    const totalHours = (this.state.tse.reduce((total, currentItem) => total = total + (currentItem.timeHours ?? 0), 0));

    return this.props.employee_id > 0 ? (
      <div>
        <div>
          Daily TimeSheet for {this.props.employee_email}<br />
          Total: <span className="time-hours-icons"> <ShowHoursIcons hourskey={'' + this.props.employee_id} hours={totalHours} /> </span> {totalHours} hours
        </div>
        <div>{this.state.message}</div>
        {!timeEntered ? <div>No time entries</div> :
          <div className="time-entries">
            {this.state.tse.map((entry) => (
              <div key={entry.timeId} className="time-entry">
                <div className="time-value time-job" >{entry.jobNumber} - {entry.jobDesc}</div>
                <div className="time-value time-project">{entry.projDesc} - {entry.workType}</div>
                <div className="time-value time-description">{entry.workDesc}</div>
                <div className="time-value time-hours">
                  <span className="time-hours-icons"> <ShowHoursIcons hourskey={'' + entry.timeId} hours={entry.timeHours ?? 0} /> </span>
                  {entry.timeHours} hours
                </div>
                {this.state.isAddOpen || this.state.isEditOpen ? <span></span> :
                  <Button className="small-buttons"
                    color="primary"
                    onClick={() => {
                      if (this.state.isEditOpen && this.state.selectedEdit == entry.timeId) {
                        this.setState({ isEditOpen: !this.state.isEditOpen })
                      } else if (this.state.isEditOpen && this.state.selectedEdit != entry.timeId) {
                        this.setState({ isEditInitialized: false })
                        this.setState({ selectedEdit: entry.timeId ?? -1 })
                      } else {
                        this.setState({ isEditInitialized: false })
                        this.setState({ selectedEdit: entry.timeId ?? -1 })
                        this.setState({ isEditOpen: !this.state.isEditOpen })
                      }
                    }
                    }
                  ><FontAwesomeIcon icon={faPenToSquare} /></Button>
                }
                {
                  this.state.isAddOpen || this.state.isEditOpen ? <span></span> :
                    <Button className="small-buttons"
                      color="primary"
                      onClick={() => {
                        this.deleteTimeEntry(entry.timeId ?? -1);
                      }
                      }
                    ><FontAwesomeIcon icon={faTrashCan} /></Button>
                }
                {
                  this.state.isAddOpen || this.state.isEditOpen ? <span></span> :
                    <Button className="small-buttons"
                      color="primary"
                      onClick={() => {
                        this.duplicateTimeEntry(entry.timeId ?? -1);
                      }
                      }
                    ><FontAwesomeIcon icon={faCopy} /> </Button>
                }
              </div>
            ))}
          </div>}
        {
          this.state.isEditOpen ? <span></span> :
            <div>
              <Button
                color="primary"
                onClick={() => this.setState({ isAddOpen: !this.state.isAddOpen })}
                style={{
                  marginBottom: '1rem',
                  marginTop: '1rem'
                }}
              ><FontAwesomeIcon icon={faCirclePlus} />  </Button>
              <Button
                color="primary"
                onClick={() => this.setState({ isRecentOpen: !this.state.isRecentOpen })}
                style={{
                  marginBottom: '1rem',
                  marginTop: '1rem'
                }}
              ><FontAwesomeIcon icon={faClock} />  </Button>
            </div>
        }
        <Collapse isOpen={this.state.isAddOpen}>
          <AddTimeForm ref={this.addRef} key={this.getKey()} employee_id={this.props.employee_id} jobs={this.state.jobs} workTypes={this.state.workTypes} handleAddTime={this.handleAddTime} />
        </Collapse>
        <Collapse isOpen={this.state.isEditOpen}>
          <EditTimeForm ref={this.editRef} key={this.state.selectedEdit} jobs={this.state.jobs} workTypes={this.state.workTypes} handleEditTime={this.handleEditTime}
            id={this.state.selectedEdit}
          />
        </Collapse>
        {
          !this.state.isRecentOpen ? <span></span> :
            <div className="recent-time-entries-container">
              <Button
                color="primary"
                onClick={() => this.setState({ isRecentOpen: !this.state.isRecentOpen })}
              ><FontAwesomeIcon icon={faClose} />  </Button>

              <div className="recent-time-entries">

                {this.state.rtse.map((entry) => (
                  <div key={entry.timeId} className="recent-time-entry" onClick={() => {
                    this.duplicateTimeEntry(entry.timeId ?? -1);
                    this.setState({ isRecentOpen: !this.state.isRecentOpen });
                  }}>
                    <div className="time-value time-job" >{entry.jobNumber} - {entry.jobDesc}</div>
                    <div className="time-value time-project">{entry.projDesc} - {entry.workType}</div>
                    <div className="time-value time-description">{entry.workDesc}</div>
                  </div>
                ))}
              </div>
            </div>
        }
      </div>
    ) : (
      <div> You must be logged in.</div>
    );
  }
}

export default DailyTimesheet;

