import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import Login from './login/Login';

export default class NavMenu extends React.PureComponent<{ employee_id: number, login_handler: Function }, { isOpen: boolean }> {
  public state = {
    isOpen: false
    };


  public render() {
    return (
        <header>

        <Navbar className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3" light>
          <Container>
            <NavbarBrand tag={Link} to="/">Timesheets2020.Web</NavbarBrand>
            <NavbarToggler onClick={this.toggle} className="mr-2" />
            <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                </NavItem>
                {/*<NavItem>*/}
                {/*  <NavLink tag={Link} className="text-dark" to="/timesheets-list">Timesheets</NavLink>*/}
                {/*</NavItem>*/}
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/add-time">Add Time</NavLink>
                            </NavItem>
                            <Login enabled={this.state.isOpen} employee_id={this.props.employee_id} login_handler={this.props.login_handler} />
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }

  private toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
}
