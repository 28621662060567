import React, { Component } from "react";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";

import "@webscopeio/react-textarea-autocomplete/style.css";

const Item = ({ entity: { name, char } }) => <div>{`${name}: ${char}`}</div>;
const Loading = ({ data }) => <div>Loading</div>;

export type EnterDescriptionProps = {
    handleChange: (selectedValue) => void;
    defaultValue?: string;
}

export type EnterDescriptionViewModel = {
};


class EnterDescription extends Component<EnterDescriptionProps, EnterDescriptionViewModel> {

    constructor(props) {
        super(props);
    }

  textarea: any;
  rta: any;

  render() {
      return (
          <textarea
              defaultValue={this.props.defaultValue}
              onChange={e => this.props.handleChange(e)}
              rows={4} cols={50}
        />
    );
  }
}

export default EnterDescription;
