import { Component } from "react";
import useState from "react";
import SelectJob from "./SelectJob";
import SelectProject from "./SelectProject";
import SelectWorkType from "./SelectWorkType";
import SelectHour from "./SelectHour";
import SaveButton from './SaveButton';
import EnterDescription from "./EnterDescription";
import { WorkTime } from '../../../models/work-time';
import { Project } from '../../../models/project';
import { Jobs } from '../../../models/jobs';
import { WorkType } from '../../../models/work-type';
import { EmployeeID } from '../../../models/employee-id';
import { Employee } from '../../../models/employee';

import "bootstrap/dist/css/bootstrap.min.css";

// IMPORTANT you need to include the default styles
import "react-responsive-tabs/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

import "../../../css/App.css";

import * as React from "react";

export type AddTimeFormViewModel = {
  selectedjob: number;
  selectedproject: number;
  selectedworktype: number;
  enteredhours: number;
  entereddescription: string;
  projects: Project[];
  enabledbutton: boolean;
};

export type AddTimeFormProps = {
  workTypes?: WorkType[];
  jobs?: Jobs[];
  employee_id?: number;
  handleAddTime?: () => void;
};

const innerRef = React.createRef<HTMLDivElement>();

export class AddTimeForm extends Component<AddTimeFormProps, AddTimeFormViewModel> {
  constructor(props) {
    super(props);
    this.state = {
      selectedjob: -1,
      selectedproject: -1,
      selectedworktype: -1,
      enteredhours: -1,
      entereddescription: "",
      projects: [],
      enabledbutton: false
    };
    this.handleWorkTypeChange = this.handleWorkTypeChange.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleJobChange = this.handleJobChange.bind(this);
    this.handleHourChange = this.handleHourChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
  }


  getProjects(jobId: number) {
    console.log("TimeForm/getProjects");
      fetch(`/api/timesheets/GetProjects?jobId=` + jobId)
      .then(response => response.json() as Promise<Project[]>)
      .then(data => {
        this.setState({
          projects: data
        });
      });
  }


  updateTimeEntry = () => {
    console.log("In updateTimeEntry");
    var d = new Date();
    var date = d.getFullYear() + '-' + ("0" + (d.getMonth() + 1)).slice(-2) + '-' + ("0" + d.getDate()).slice(-2)

    const data = {
      NumHours: this.state.enteredhours,
      NumWorkTypeId: this.state.selectedworktype,
      DtDate: date,
      TxtDesc: this.state.entereddescription,
      NumProjectId: this.state.selectedproject,
      NumEmployeeId: this.props.employee_id,
    }
    console.log(data);

      fetch(`/api/timesheets/AddTime`, {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        console.log("in TimeForm about to call handleAddTime");
        console.log(this);

        this.props.handleAddTime?.();
        this.setState({
          selectedjob: -1,
          selectedproject: -1,
          selectedworktype: -1,
          enteredhours: -1,
          entereddescription: "",
          projects: [],
          enabledbutton: false
        }
        );
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }


  afterSetStateFinished = () => {
    let enabled = this.state.selectedjob > 0 && this.state.selectedproject > 0 && this.state.selectedworktype > 0 && this.state.enteredhours > -1 && this.state.entereddescription.length > 5;
    this.setState({
      enabledbutton: enabled,
    });

  }

  handleJobChange = (selectedOption) => {
    this.getProjects(selectedOption.value);
    this.setState({
      selectedjob: selectedOption.value,
    }, () => {
      this.afterSetStateFinished();
    });
  };

  handleProjectChange = (selectedOption) => {
    this.setState({
      selectedproject: selectedOption.value
    }, () => {
      this.afterSetStateFinished();
    });
  };

  handleWorkTypeChange = (selectedOption) => {
    this.setState({
      selectedworktype: selectedOption.value,
    });
  };

  handleHourChange = (values) => {
    this.setState({
      enteredhours: values[0],
    }, () => {
      this.afterSetStateFinished();
    });
  };

  handleDescriptionChange = (selectedOption) => {
    this.setState({
      entereddescription: selectedOption.target.value,
    }, () => {
      this.afterSetStateFinished();
    });
  };

  handleSaveButton = (selectedOption) => {
    console.log("Button Clicked 2 in Time Form");
    //        console.log(this.state);
    console.log(this.state.selectedjob);
    console.log(this.state.selectedproject);
    console.log(this.state.enteredhours);
    console.log(this.state.entereddescription);
    this.updateTimeEntry();
  };

  getWrapperRef = () => {
    return innerRef;
  }


  render() {

    return (
      <div className="container add-time-form" ref={innerRef}>
        <div className="row select-drop-down">
          <div className="col-md-6">
            <SelectJob jobs={this.props.jobs} handleChange={this.handleJobChange} />
          </div>
        </div>
        <div className="row select-drop-down">
          <div className="col-md-6">
            <SelectProject projects={this.state.projects} handleChange={this.handleProjectChange} />
          </div>
        </div>
        <div className="row select-drop-down">
          <div className="col-md-6">
            <SelectWorkType worktypes={this.props.workTypes} handleChange={this.handleWorkTypeChange} />
          </div>
        </div>
        <div className="row select-hour">
          <div className="col-md-6">
            <SelectHour handleChange={this.handleHourChange} selectedHours={1} />
          </div>
        </div>
        <div className="row enter-description">
          <div className="col-md-6">
            <EnterDescription handleChange={this.handleDescriptionChange} />
          </div>
        </div>
        <div className="row enter-description">
          <div className="col-md-6">
            <SaveButton saveHandler={this.handleSaveButton} enabled={this.state.enabledbutton} />
          </div>
        </div>
      </div>
    );
  }
}

export const AddTimeFormRef = React.forwardRef((props, ref: useState.ForwardedRef<AddTimeForm>) => <AddTimeForm
  ref={ref} {...props}
/>);