import React, { Component } from "react";
import Select from "react-select";
//import { Tabs, Tab } from "react-bootstrap";
import { WorkType } from "../../../models/work-type";


export type SelectWorktypeProps = {
    worktypes: WorkType[] | undefined;
    handleChange: (selectedValue) => void;
    selectedWorkTypeValue?: number | null;
    selectedWorkTypeLabel?: string | null; 
}

export type SelectWorktypeViewModel = {
    selectedOption: number;
};

class SelectWorkType extends Component<SelectWorktypeProps, SelectWorktypeViewModel> {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: -1,
        };

    }



    render() {
        const { selectedOption } = this.state;
        let worktypeOptions = this.props.worktypes?.map(p => { return { value: p.numWorkTypeId, label: p.txtWorkType }; });
        let defaultSelect = [{ label: this.props.selectedWorkTypeLabel, value: this.props.selectedWorkTypeValue }]

        return (
            <Select id="worktypeId" options={worktypeOptions} defaultValue={defaultSelect} onChange={e => this.props.handleChange(e)} />
      );
  }
}

export default SelectWorkType;
