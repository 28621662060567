import React, { Component } from "react";
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck} from '@fortawesome/free-solid-svg-icons'


export type SaveButtonProps = {
    enabled: boolean;
    saveHandler: (selectedValue) => void;
}

export type SaveButtonViewModel = {
};


class SaveButton extends Component<SaveButtonProps, SaveButtonViewModel> {
    constructor(props) {
        super(props);
    }

    render() {
        
        return (
            <Button color="primary" onClick={this.props.saveHandler} id="saveButton" disabled={!this.props.enabled}> <FontAwesomeIcon icon={faCircleCheck}/> </Button>
      );
  }
}

export default SaveButton;
