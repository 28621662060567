import React, { Component } from "react";
import { Range } from "react-range";


export type SelectHoursProps = {
    handleChange: (selectedValue) => void;
  selectedHours: number;
    
}

export type SelectHoursViewModel = {
};

class SelectHour extends Component<SelectHoursProps, SelectHoursViewModel> {
    constructor(props) {
        super(props);
    }
    state = { values: [this.props.selectedHours] };

  render() {
    return (
      <div className="hours-container">
        <label className="hours-label">hours:</label>
        <div className="hours-slider">
          <Range
            step={0.25}
            min={0}
            max={8}
            values={this.state.values}
                    onChange={(values) => {
                        this.props.handleChange(values);
                        this.setState({ values });
                    }}
            renderTrack={({ props, children }) => (
              <div
                className="hours-track"
                {...props}
                style={{
                  ...props.style,
                  height: "6px",
                  width: "80%",
                  backgroundColor: "#ccc",
                  float: "right",
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                className="hours-thumb"
                {...props}
                style={{
                  ...props.style,
                  height: "64px",
                  width: "44px",
                  backgroundColor: "#999",
                  textAlign: "center",
                  paddingTop: "12px",
                }}
              >
                {this.state.values} <br />
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}

export default SelectHour;
