import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleLogin } from 'react-google-login';
import { Employee } from '../../models/employee';

import Cookies from 'universal-cookie';

export type LoginProps = {
    enabled: boolean,
    employee_id: number,
    login_handler: Function
}
export type LoginViewModel = {
    employeeid: number,
    email: string
};


class Login extends React.Component<LoginProps, LoginViewModel> {
    constructor(props) {
    super(props);
    this.state = {
        employeeid: 0,
        email: "no email",
        };
    this.responseGoogleLogin = this.responseGoogleLogin.bind(this);
  }

    responseGoogleLogin(response) {
        // POST
//        console.log(response);
        if (response.error == undefined) {
            var emailAddress = response.profileObj.email;
            var googleId = response.profileObj.googleId;
            fetch(`/api/timesheets/GetEmployeeID?emailAddress=` + emailAddress)
                .then(response => response.json() as Promise<Employee>)
                .then(data => {
                    var employeeID = data.numEmployeeId;
                    console.log("Your employee ID is " + employeeID);
                    console.log("You are logged in as " + response.profileObj.email);
//                    console.log("Name =  " + response.profileObj.name);
//                    console.log("First Name =  " + response.profileObj.givenName);
//                    console.log("Last Name = " + response.profileObj.familyName);
//                    console.log("Google Unique ID" + response.profileObj.googleId);
//                    console.log("image URL =  " + response.profileObj.imageUrl);
//                    console.log("access token =  " + response.accessToken);
                    const cookies = new Cookies();
                    cookies.set('ts2020-loggedin', 1, { path: '/' });
                    cookies.set('ts2020-email', emailAddress, { path: '/' });
                    cookies.set('ts2020-employeeid', employeeID, { path: '/' });
                    cookies.set('ts2020-name', response.profileObj.name, { path: '/' });
                    cookies.set('ts2020-image', response.profileObj.imageUrl, { path: '/' });
                    cookies.set('ts2020-googleid', googleId, { path: '/' });
//                    console.log("calling this.updateData");
//                    console.log("employeeID= " + employeeID);
//                    console.log("emailAddress= " + emailAddress);
//                    console.log(this);
                    this.updateData(employeeID, emailAddress);
//                    console.log("calling this.props.login_handler");
                    this.props.login_handler(employeeID, emailAddress);
                });
        } else {
            console.log(response.error);
            const cookies = new Cookies();
            cookies.remove('ts2020-loggedin', { path: '/' });
            cookies.remove('ts2020-email', { path: '/' });
            cookies.remove('ts2020-employeeid',  { path: '/' });
            cookies.remove('ts2020-name', { path: '/' });
            cookies.remove('ts2020-image', { path: '/' });
            cookies.remove('ts2020-googleid', { path: '/' });
        }

    }

    updateData = (employeeId: number, emailAddress: string) => {
        console.log("setting state of Login in updateData");
        this.setState({
            employeeid: employeeId,
            email: emailAddress,
        });
    }

render() {

    return (
        <div>
          <GoogleLogin
            clientId="880661456168-oskd38sla7ss5csr2slhhalks9cue6gi.apps.googleusercontent.com"
            buttonText="Login"
            onSuccess={this.responseGoogleLogin}
            onFailure={this.responseGoogleLogin}
            cookiePolicy={'single_host_origin'}
            />
            <div>
                logged in as {this.state.email} 
            </div>
        </div>
    );
  }
}

export default Login;

