import * as React from 'react';
import { Routes, Route } from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import AddTime from './components/time/AddTime';
import DailyTimesheet from './components/time/DailyTimesheet';
import Login from './components/login/Login';
import './custom.css'


export type AppModel = {
  employee_id: number;
  employee_email: string;
  loggedIn: boolean;
}

class App extends React.Component<{}, AppModel> {
    constructor(props) {
        super(props);
        this.state = {
            employee_id: -1,
            employee_email: '',
            loggedIn: false
        }
    }


    setLoggedInUser = (employee_id: number, employee_email: string) => {
        console.log("in setLoggedInUser");
        console.log(this);
        this.setState({
            employee_id: employee_id,
            employee_email: employee_email,
            loggedIn: true
        });
    }
    render() {
        return (
            <Layout employee_id={this.state.employee_id} login_handler={this.setLoggedInUser} >
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/timesheets-list/:startDateIndex?' />
                    <Route path='/add-time/' element={<DailyTimesheet employee_id={this.state.employee_id} employee_email={this.state.employee_email}/>}  />
                </Routes>
            </Layout>
        );
    }
}

export default App;
