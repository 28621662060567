import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle as faCircleWhole } from '@fortawesome/free-solid-svg-icons'
import { faCircleHalfStroke as faCircle50 } from '@fortawesome/free-solid-svg-icons'
import { faCircleDot as faCircle75 } from '@fortawesome/free-solid-svg-icons'
import { faCircleDot as faCircle25 } from '@fortawesome/free-regular-svg-icons'



export type ShowHoursIconsProps = {
    hours: number;
    hourskey: string;
}

export type ShowHoursIconsViewModel = {
};


class ShowHoursIcons extends Component<ShowHoursIconsProps, ShowHoursIconsViewModel> {
    static keyCount = 0;
    constructor(props) {
        super(props);
    }

    static generateKey(pre: string) {
        ShowHoursIcons.keyCount++;
        console.log("ShowHoursIcons/this.keyCount");
        console.log(ShowHoursIcons.keyCount);
        return `${pre}_${ShowHoursIcons.keyCount}`;
}

    render() {
        var tmp: number[] = [];
        var inthours = parseInt(Math.floor(this.props.hours).toString());
        var remainder = (this.props.hours - inthours) * 4;
        for (var i = 0; i < inthours; i++) {
            tmp.push(i);
        }
        const strkey = this.props.hourskey;
        var indents = tmp.map(function (i) {
            return (
                <FontAwesomeIcon className="time-hours-icon" icon={faCircleWhole} key={ShowHoursIcons.generateKey(strkey)} />
            );
        });
        if (remainder == 1) {
            indents.push(<FontAwesomeIcon className="time-hours-icon"  icon={faCircle25} key={ShowHoursIcons.generateKey(strkey)} />);
        }
        if (remainder == 2) {
            indents.push(<FontAwesomeIcon className="time-hours-icon" icon={faCircle50} key={ShowHoursIcons.generateKey(strkey)} />);
        }
        if (remainder == 3) {
            indents.push(<FontAwesomeIcon className="time-hours-icon" icon={faCircle75} key={ShowHoursIcons.generateKey(strkey)} />);
        }
        return indents;
    }
}

export default ShowHoursIcons;
