import React, { Component } from "react";
import Select from "react-select";
//import { Tabs, Tab } from "react-bootstrap";
import { Project } from "../../../models/project";

export type SelectProjectProps = {
    projects: Project[];
    handleChange: (selectedValue) => void;
    selectedProjectValue?: number | null;
    selectedProjectLabel?: string | null;
}

export type SelectProjectViewModel = {
};

class SelectProject extends Component<SelectProjectProps, SelectProjectViewModel> {
  constructor(props) {
    super(props);
  }

  render() {
      let projectOptions = this.props.projects.map(p => { return { label: p.txtDescription, value: p.numProjectId }; });
      let defaultSelect = [{ label: this.props.selectedProjectLabel, value: this.props.selectedProjectValue }]

      return (
          <Select id="projectId" options={projectOptions} defaultValue={defaultSelect} onChange={e => this.props.handleChange(e)} />
    );
  }
}

export default SelectProject;
